<template>
	<div class="container">
		<common-shell type="valveOperate"></common-shell>
	</div>
</template>

<script>
import CommonShell from '../comps/CommonShell.vue'

export default {
	name: 'ValveOperate',
	components: { CommonShell },
}
</script>
<style lang="scss" scoped>
.container {
	height: 100%;
}
</style>
